<template>
  <div>
    <breadCrumb :title="$t('suo-you-mai-jia')" :menuList="menuList"></breadCrumb>

    <section class="mb-2">
      <div class="container">
        <div class="row gutters-10 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1">
          
          <div class="col" v-for="(shop, i) in sellerList" :key="i" @click="toDetail(item)">
            <div class="row no-gutters bg-white align-items-center border border-light rounded hov-shadow-md mb-3 has-transition">
              <div class="col-4">
                <a  class="d-block p-3" tabindex="0">
                  <img  :src="shop.url" class="img-fluid lazyload" key="">
                </a>
              </div>
              <div class="col-8 border-left border-light">
                <div class="p-3 text-left">
                  <h2 class="h6 fw-600 text-truncate">
                    <a  class="text-reset" tabindex="0">{{ shop.name }}</a>
                  </h2>
                  <div class="rating rating-sm mb-2">
                    <i class="las la-star active" v-for="m in shop.star" :key="m"></i>
                    <i class="las la-star" v-for="n in 5" :key="n" v-if="n > shop.star"></i>
                  </div>
                  <a class="btn btn-soft-primary btn-sm" href="javascript: void(0)">
                    {{ $t('cha-kan-dian-pu') }} <i class="las la-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
                    
        </div>
        <div class="aiz-pagination aiz-pagination-center mt-4 mb-4" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page"></pageBar>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import pageBar from '@/components/page'
export default {
  components: {
    breadCrumb,
    pageBar
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('suo-you-mai-jia'),
          path: 'blog'
        },
      ],
      sellerList: [],
      page: {
        page: 1,
        limit: 12,
        total: 1
      }
     
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'shopInfo'
      })
    }
  }
}
</script>